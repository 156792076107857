/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/blog-images/blumen.png",
    alt: "blumen"
  })), "\n", React.createElement(_components.p, null, "Hi Blumen team! I heard that you are growing fast and have some currently open roles for senior software engineers, and thought I'd reach out."), "\n", React.createElement(_components.p, null, React.createElement("br"), "I am a full-stack web, mobile, and data engineer with over 12 years of\nexperience working with technologies such as Ruby on Rails,\nJavaScript/TypeScript, React, React Native (Expo), Elasticsearch, AWS/Firebase,\nSQL, Snowflake, DBT, and recently, OpenAI APIs, RAG and agentic frameworks such\nas llamaindex. I'm currently open to contract work opportunities and would love\nto learn more about your projects, challenges, and how I might be able to\ncontribute to your team."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "I'm the founder of ", React.createElement(_components.a, {
    href: "https://impulse.training"
  }, "impulse"), ", and we're helping people\novercome addictions and other unwanted patterns of thinking and behavior. In my most\nrecent role, I led the engineering team at an Australian consumer marketplace and\ndata startup, as we grew from a $0.6M to a $34M valuation in 2 years. I am passionate\nabout building high-performing teams and processes, such as mob programming, but\nI also love grinding away at tough technical problems."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "I am seeking a 3-6 month contract where I can bring a ton of value to a\ngrowing team. If my experience aligns with your needs, I would be really happy\nto discuss how I can support! I am open to working at a discounted\nrate and would be happy to volunteer some time initially to ensure there's a good fit."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Cheers!"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "~M"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "mailto:hi@michaeldawson.com.au"
  }, "Say hi")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
